import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { addMessage, selectAuth } from "../../global/store/global-slice";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import {
  DraftBoardListingResponse,
  MessageSeverity,
} from "../../../generated/api_types";
import { GENERIC_ERROR_MESSAGE } from "../../global/components/messages";
import DraftBoardService from "../../../api/draft-board";
import LoadingPage from "../../global/components/loading-page";
import { LinkContainer } from "react-router-bootstrap";

export default function DraftList() {
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const [draftBoards, setDraftBoards] = useState(
    [] as DraftBoardListingResponse[]
  );
  const [loading, setLoading] = useState(false);
  const [draftBoardName, setDraftBoardName] = useState("");

  const loadDraftBoards = useCallback(async () => {
    try {
      const resp = await DraftBoardService.getDraftBoards(
        auth!.oauth.access_token
      );
      if (resp.message) {
        dispatch(
          addMessage({ message: { ...resp.message, timestamp: Date.now() } })
        );
      }
      if (resp.data) {
        setDraftBoards(resp.data);
      }
    } catch (e) {
      dispatch(
        addMessage({
          message: {
            severity: MessageSeverity.Error,
            message: GENERIC_ERROR_MESSAGE,
            timestamp: Date.now(),
          },
        })
      );
    }
  }, [auth, dispatch]);

  const createDraftBoard = useCallback(async () => {
    if (!draftBoardName) {
      return;
    }

    try {
      const resp = await DraftBoardService.createDraftBoard(
        auth!.oauth.access_token,
        { title: draftBoardName }
      );
      if (resp.message) {
        dispatch(
          addMessage({ message: { ...resp.message, timestamp: Date.now() } })
        );
        if (resp.message.severity === MessageSeverity.Success) {
          await loadDraftBoards();
          setDraftBoardName("");
        }
      }
    } catch (e) {
      dispatch(
        addMessage({
          message: {
            severity: MessageSeverity.Error,
            message: GENERIC_ERROR_MESSAGE,
            timestamp: Date.now(),
          },
        })
      );
    }
  }, [auth, dispatch, draftBoardName, loadDraftBoards]);

  useEffect(() => {
    if (auth) {
      setLoading(true);
      loadDraftBoards().catch(console.error);
      setLoading(false);
    }
  }, [auth, loadDraftBoards]);

  if (loading) {
    return <LoadingPage />;
  }

  if (!auth) {
    return (
      <Container>
        <Alert variant="info">
          You must be logged in to view or create a draft board
        </Alert>
      </Container>
    );
  }

  return (
    <Container>
      <Form
        onSubmit={async (e) => {
          e.preventDefault();
          setLoading(true);
          try {
            await createDraftBoard();
          } finally {
            setLoading(false);
          }
        }}
      >
        <Row className="align-items-center my-3">
          <Col xs={0} md={4} lg={6} />
          <Col xs={12} sm={6} lg={4}>
            <Form.Label htmlFor="newDraftBoardName" visuallyHidden>
              Draft Board Name
            </Form.Label>
            <Form.Control
              id="newDraftBoardName"
              placeholder="Draft Board Name"
              value={draftBoardName}
              onChange={(e) => setDraftBoardName(e.target.value)}
            />
          </Col>
          <Col xs={12} sm={4} md={2}>
            <Button type="submit">Create New Board</Button>
          </Col>
        </Row>
      </Form>

      {draftBoards ? (
        <ListGroup>
          {draftBoards.map((board) => (
            <ListGroupItem key={board.id} className="py-3 px-4">
              <LinkContainer to={`/draft/${board.id}`}>
                <Alert.Link>{board.title}</Alert.Link>
              </LinkContainer>
            </ListGroupItem>
          ))}
        </ListGroup>
      ) : (
        <Alert variant="secondary">
          You currently have no draft boards. Use the input above to create one.
        </Alert>
      )}
    </Container>
  );
}
