import { Container } from "react-bootstrap";
import LoadingIndicator from "./loading";
import React from "react";

function LoadingPage() {
  return (
    <Container style={{ textAlign: "center", paddingTop: "3rem" }}>
      <LoadingIndicator />
    </Container>
  );
}

export default LoadingPage;
