import { useEffect, useState } from "react";
import LoadingPage from "../../global/components/loading-page";
import LeagueNav from "./nav";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { addMessage } from "../../global/store/global-slice";
import {
  MessageSeverity,
  TeamsByDivisionResponse,
} from "../../../generated/api_types";
import { GENERIC_ERROR_MESSAGE } from "../../global/components/messages";
import { useAppDispatch } from "../../../redux/hooks";
import TeamService from "../../../api/team";
import { useParams } from "react-router-dom";

function LeagueHome() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const leagueName = params["leagueName"];

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null as TeamsByDivisionResponse | null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await TeamService.getTeamsByDivision(leagueName!);
        if (resp.message) {
          dispatch(
            addMessage({ message: { ...resp.message, timestamp: Date.now() } })
          );
        }
        if (resp.data) {
          setData(resp.data);
          setLoading(false);
        }
      } catch (e) {
        dispatch(
          addMessage({
            message: {
              severity: MessageSeverity.Error,
              message: GENERIC_ERROR_MESSAGE,
              timestamp: Date.now(),
            },
          })
        );
      }
    };
    fetchData().catch(console.error);
  }, [dispatch, leagueName]);

  return (
    <Container>
      <LeagueNav />
      {loading ? (
        <LoadingPage />
      ) : (
        <div>
          <Row xs={1} sm={2} lg={4}>
            {data!.divisions.map((division) => (
              <Col key={`${division.conference} ${division.division}`}>
                <Card
                  border={division.conference === "AFC" ? "danger" : "info"}
                  className="my-3"
                >
                  <Card.Header>
                    {division.conference} {division.division}
                  </Card.Header>
                  <Card.Body>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Team</th>
                          <th>W</th>
                          <th>L</th>
                          <th>T</th>
                        </tr>
                      </thead>
                      <tbody>
                        {division.teams.map((team) => (
                          <tr key={team.id}>
                            <td>
                              <img
                                style={{ width: "25px", height: "25px" }}
                                alt=""
                                src={
                                  team.logo_url ||
                                  "https://via.placeholder.com/24?text=Logo"
                                }
                              />
                              &nbsp;&nbsp;
                              {team.abbreviation}
                            </td>
                            <td>{team.wins}</td>
                            <td>{team.losses}</td>
                            <td>{team.ties}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </Container>
  );
}

export default LeagueHome;
