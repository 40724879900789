import { MessageSeverity } from "../../../generated/api_types";
import { Toast } from "react-bootstrap";
import { Variant } from "react-bootstrap/types";

export interface MessageData {
  message: string;
  severity: MessageSeverity;
  timestamp: number;
}

interface Props {
  message: MessageData;
  clearMessage: () => void;
}

const severityToBgName = (severity: MessageSeverity): Variant => {
  switch (severity) {
    case MessageSeverity.Success:
      return "success";
    case MessageSeverity.Info:
      return "info";
    case MessageSeverity.Warning:
      return "warning";
    case MessageSeverity.Error:
      return "danger";
  }
};

function Message({ message, clearMessage }: Props) {
  return (
    <Toast
      bg={severityToBgName(message.severity)}
      onClose={clearMessage}
      show={true}
      delay={10000}
      autohide
    >
      <Toast.Header>Notification</Toast.Header>
      <Toast.Body>{message.message}</Toast.Body>
    </Toast>
  );
}

export default Message;
