import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthResponse } from "../../../generated/api_types";
import { RootState } from "../../../redux/store";
import filter from "lodash/filter";
import { MessageData } from "../components/message";

export interface GlobalState {
  messages: MessageData[];
  auth: AuthResponse | null;
}

const initialState: GlobalState = {
  messages: [],
  auth: null,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<{ message: MessageData }>) => {
      state.messages = [...state.messages, action.payload.message];
    },
    removeMessage: (state, action: PayloadAction<{ index: number }>) => {
      if (state.messages.length <= 1) {
        state.messages = [];
      } else {
        state.messages = filter(
          state.messages,
          (msg, i) => i !== action.payload.index
        );
      }
    },
    setAuth: (state, action: PayloadAction<{ auth: AuthResponse | null }>) => {
      state.auth = action.payload.auth;
    },
  },
});

export const { addMessage, removeMessage, setAuth } = globalSlice.actions;

export const selectMessages = (state: RootState) => state.global.messages;
export const selectAuth = (state: RootState) => state.global.auth;

export default globalSlice.reducer;
