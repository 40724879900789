import {
  AuthRefreshBody,
  AuthRequestBody,
  AuthResponse,
  Response,
} from "../generated/api_types";

export default class AuthService {
  static async postAuth(
    request: AuthRequestBody
  ): Promise<Response<AuthResponse>> {
    const response = await fetch(`/api/auth`, {
      method: "POST",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.json();
  }

  static async putAuth(
    request: AuthRefreshBody
  ): Promise<Response<AuthResponse>> {
    const response = await fetch(`/api/auth`, {
      method: "PUT",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.json();
  }
}
