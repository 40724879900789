import { Card, Col, Container, Row } from "react-bootstrap";
import LoadingPage from "../../global/components/loading-page";
import { useAppSelector } from "../../../redux/hooks";
import { Link } from "react-router-dom";
import {
  selectLeagues,
  selectLeaguesLoading,
} from "../../league/store/leagues-slice";

function Home() {
  const leagues = useAppSelector(selectLeagues);
  const loading = useAppSelector(selectLeaguesLoading);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <Row xs={1} sm={2} md={3} lg={4}>
        {leagues.map((league) => (
          <Col key={league.id}>
            <Link to={`/leagues/${league.name}`}>
              <Card>
                <Card.Header>{league.display_name}</Card.Header>
                {league.icon ? <Card.Img src={league.icon} /> : null}
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Home;
