import { Response, TeamsByDivisionResponse } from "../generated/api_types";

export default class TeamService {
  static async getTeamsByDivision(
    league_name: string
  ): Promise<Response<TeamsByDivisionResponse>> {
    const response = await fetch(
      `/api/leagues/${league_name}/teams/by_division`,
      {
        method: "GET",
      }
    );
    return response.json();
  }
}
