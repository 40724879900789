import {
  CreateDraftBoardRequest,
  DraftBoardListingResponse,
  Response,
  DraftBoardPlayerRequest,
  DraftBoardPlayerResponse,
  DraftBoardArchetypeResponse,
  DraftBoardPlayerPatchRequest,
  UpdateDraftBoardNeedsRequest,
} from "../generated/api_types";

export default class DraftBoardService {
  static async getDraftBoards(
    access_token: string
  ): Promise<Response<DraftBoardListingResponse[]>> {
    const response = await fetch(`/api/draft_boards`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.json();
  }

  static async createDraftBoard(
    access_token: string,
    request: CreateDraftBoardRequest
  ): Promise<Response<DraftBoardListingResponse>> {
    const response = await fetch(`/api/draft_boards`, {
      method: "POST",
      body: JSON.stringify(request),
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    });
    return response.json();
  }

  static async getDraftBoardDetails(
    access_token: string,
    draft_board_id: string
  ): Promise<Response<DraftBoardListingResponse>> {
    const response = await fetch(`/api/draft_boards/${draft_board_id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.json();
  }

  static async updateTeamNeeds(
    access_token: string,
    draft_board_id: string,
    data: UpdateDraftBoardNeedsRequest
  ): Promise<Response<DraftBoardListingResponse>> {
    const response = await fetch(`/api/draft_boards/${draft_board_id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  static async getDraftBoardArchetypes(): Promise<
    Response<DraftBoardArchetypeResponse>
  > {
    const response = await fetch(`/api/draft_boards/archetypes`, {
      method: "GET",
    });
    return response.json();
  }

  static async listDraftBoardPlayers(
    access_token: string,
    draft_board_id: string
  ): Promise<Response<DraftBoardPlayerResponse[]>> {
    const response = await fetch(
      `/api/draft_boards/${draft_board_id}/players`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return response.json();
  }

  static async addDraftBoardPlayer(
    access_token: string,
    draft_board_id: string,
    request: DraftBoardPlayerRequest
  ): Promise<Response<void>> {
    const response = await fetch(
      `/api/draft_boards/${draft_board_id}/players`,
      {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.json();
  }

  static async updateDraftBoardPlayer(
    access_token: string,
    draft_board_id: string,
    player_id: string,
    request: DraftBoardPlayerRequest
  ): Promise<Response<void>> {
    const response = await fetch(
      `/api/draft_boards/${draft_board_id}/players/${player_id}`,
      {
        method: "PUT",
        body: JSON.stringify(request),
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.json();
  }

  static async setPlayerDrafted(
    access_token: string,
    draft_board_id: string,
    player_id: string,
    request: DraftBoardPlayerPatchRequest
  ): Promise<Response<void>> {
    const response = await fetch(
      `/api/draft_boards/${draft_board_id}/players/${player_id}`,
      {
        method: "PATCH",
        body: JSON.stringify(request),
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.json();
  }
}
