/*
 Generated by typeshare 1.0.0
*/

export type DraftBoardArchetypeResponse = Record<Position, Record<Archetype, AttributeVerboseInfo[]>>;

export interface AuthRequestBody {
	code: string;
	redirect_uri: string;
}

export interface AuthRefreshBody {
	refresh_token: string;
}

export interface CreateDraftBoardRequest {
	title: string;
}

export interface UpdateDraftBoardNeedsRequest {
	needs: Archetype[];
}

export interface DraftBoardPlayerRequest {
	listed_position: Position;
	archetype: Archetype;
	name: string;
	age?: number;
	height?: number;
	weight?: number;
	projected_round?: DraftRound;
	actual_round?: DraftRound;
	notes: string;
	forty_time?: number;
	three_cone_time?: number;
	shuttle_time?: number;
	bench_press_reps?: number;
	vert_jump_height?: number;
	attributes: Record<Attribute, AttributeGrade>;
	drafted: boolean;
}

export interface DraftBoardPlayerPatchRequest {
	drafted: boolean;
}

export interface AuthResponse {
	user_id: string;
	oauth: OAuthResponse;
}

export interface OAuthResponse {
	access_token: string;
	token_type: string;
	expires_in: number;
	refresh_token: string;
	scope: string;
}

export interface DraftBoardListingResponse {
	id: string;
	title: string;
	needs: string[];
}

export interface DraftBoardPlayerResponse {
	id: string;
	listed_position: Position;
	archetype: Archetype;
	name: string;
	age?: number;
	height?: number;
	weight?: number;
	projected_round?: DraftRound;
	actual_round?: DraftRound;
	notes: string;
	forty_time?: number;
	three_cone_time?: number;
	shuttle_time?: number;
	bench_press_reps?: number;
	vert_jump_height?: number;
	drafted: boolean;
	attributes: DraftBoardPlayerAttributeResponse[];
	estimate_spd?: number;
	estimate_acc?: number;
	estimate_agi?: number;
	estimate_cod?: number;
	estimate_str?: number;
	estimate_jmp?: number;
	physical_score?: number;
	overall_score?: number;
}

export interface DraftBoardPlayerAttributeResponse {
	attribute: Attribute;
	grade: AttributeGrade;
}

export interface InjuryResponse {
	injury: string;
	duration: number;
}

export interface LeagueResponse {
	id: number;
	name: string;
	display_name: string;
	icon?: string;
	banner?: string;
}

export interface PlayoffsResponse {
	seasons: PlayoffResponse[];
}

export interface PlayoffResponse {
	afc: PlayoffConferenceResponse;
	nfc: PlayoffConferenceResponse;
}

export interface PlayoffConferenceResponse {
	teams: TeamRecordResponse[];
}

export interface Response<T> {
	message?: Message;
	data?: T;
}

export interface Message {
	message: string;
	severity: MessageSeverity;
}

export interface PlayerLeadersResponse {
	passing: PlayerPasserResponse[];
	rushing: PlayerOffenseResponse[];
	receiving: PlayerOffenseResponse[];
	tackles: PlayerDefenseResponse[];
	sacks: PlayerDefenseResponse[];
	interceptions: PlayerDefenseResponse[];
}

export interface PlayerInfoResponse {
	id: number;
	name: string;
	number: number;
	team_abbr: string;
	team_logo_url: string;
	position: string;
	games_played: number;
	years_pro: number;
	injury?: InjuryResponse;
}

export interface PlayerPasserResponse {
	yards: number;
	touchdowns: number;
	interceptions: number;
}

export interface PlayerOffenseResponse {
	id: number;
	name: string;
	number: number;
	team_abbr: string;
	team_logo_url: string;
	position: string;
	games_played: number;
	yards: number;
	touchdowns: number;
	yards_per_play: number;
}

export interface PlayerDefenseResponse {
	id: number;
	name: string;
	number: number;
	team_abbr: string;
	team_logo_url: string;
	position: string;
	games_played: number;
	tackles: number;
	sacks: number;
	interceptions: number;
}

export interface TeamsByDivisionResponse {
	divisions: DivisionResponse[];
}

export interface DivisionResponse {
	conference: string;
	division: string;
	teams: TeamRecordResponse[];
}

export interface TeamRecordResponse {
	id: number;
	city_name: string;
	team_name: string;
	abbreviation: string;
	logo_url: string;
	primary_color: string;
	secondary_color: string;
	wins: number;
	losses: number;
	ties: number;
	conference_wins: number;
	conference_losses: number;
	conference_ties: number;
	division_wins: number;
	division_losses: number;
	division_ties: number;
}

export interface AttributeVerboseInfo {
	code: Attribute;
	abbr: string;
	uses_combine_score: boolean;
	uses_letter_grades: boolean;
}

export enum MessageSeverity {
	Success = "Success",
	Info = "Info",
	Warning = "Warning",
	Error = "Error",
}

export enum Position {
	QB = "QB",
	HB = "HB",
	FB = "FB",
	TE = "TE",
	WR = "WR",
	LT = "LT",
	RT = "RT",
	LG = "LG",
	RG = "RG",
	C = "C",
	DT = "DT",
	LE = "LE",
	RE = "RE",
	LOLB = "LOLB",
	MLB = "MLB",
	ROLB = "ROLB",
	CB = "CB",
	FS = "FS",
	SS = "SS",
	K = "K",
	P = "P",
}

export enum Archetype {
	ScramblerQB = "ScramblerQB",
	ImproviserQB = "ImproviserQB",
	StrongArmQB = "StrongArmQB",
	FieldGeneralQB = "FieldGeneralQB",
	PowerBackHB = "PowerBackHB",
	ElusiveBackHB = "ElusiveBackHB",
	ReceivingBackHB = "ReceivingBackHB",
	UtilityFB = "UtilityFB",
	BlockingFB = "BlockingFB",
	PossessionTE = "PossessionTE",
	VerticalThreatTE = "VerticalThreatTE",
	BlockingTE = "BlockingTE",
	DeepThreatWR = "DeepThreatWR",
	PlaymakerWR = "PlaymakerWR",
	SlotWR = "SlotWR",
	PhysicalWR = "PhysicalWR",
	AgileOL = "AgileOL",
	PowerOL = "PowerOL",
	PassProtectorOL = "PassProtectorOL",
	PowerRusherDL = "PowerRusherDL",
	SpeedRusherDL = "SpeedRusherDL",
	RunStopperDL = "RunStopperDL",
	PowerRusherEDGE = "PowerRusherEDGE",
	SpeedRusherEDGE = "SpeedRusherEDGE",
	RunStopperEDGE = "RunStopperEDGE",
	PassCoverageLB = "PassCoverageLB",
	FieldGeneralLB = "FieldGeneralLB",
	RunStopperLB = "RunStopperLB",
	ZoneCB = "ZoneCB",
	ManToManCB = "ManToManCB",
	SlotCB = "SlotCB",
	ZoneS = "ZoneS",
	RunSupportS = "RunSupportS",
	HybridS = "HybridS",
	PowerK = "PowerK",
	AccurateK = "AccurateK",
}

export enum Attribute {
	Speed = "Speed",
	Acceleration = "Acceleration",
	Agility = "Agility",
	Strength = "Strength",
	Awareness = "Awareness",
	Jump = "Jump",
	Stamina = "Stamina",
	Toughness = "Toughness",
	Injury = "Injury",
	ChangeOfDirection = "ChangeOfDirection",
	ThrowPower = "ThrowPower",
	ShortAcc = "ShortAcc",
	MediumAcc = "MediumAcc",
	DeepAcc = "DeepAcc",
	ThrowOnRun = "ThrowOnRun",
	PlayAction = "PlayAction",
	BreakSack = "BreakSack",
	ThrowUnderPressure = "ThrowUnderPressure",
	Carrying = "Carrying",
	SpinMove = "SpinMove",
	JukeMove = "JukeMove",
	BreakTackle = "BreakTackle",
	BallCarryVision = "BallCarryVision",
	Trucking = "Trucking",
	StiffArm = "StiffArm",
	Catch = "Catch",
	SpecCatch = "SpecCatch",
	Release = "Release",
	CatchInTraffic = "CatchInTraffic",
	ShortRoute = "ShortRoute",
	MediumRoute = "MediumRoute",
	DeepRoute = "DeepRoute",
	KickReturn = "KickReturn",
	PassBlock = "PassBlock",
	PassBlockPower = "PassBlockPower",
	PassBlockFinesse = "PassBlockFinesse",
	RunBlock = "RunBlock",
	RunBlockPower = "RunBlockPower",
	RunBlockFinesse = "RunBlockFinesse",
	LeadBlock = "LeadBlock",
	ImpactBlock = "ImpactBlock",
	Tackle = "Tackle",
	HitPower = "HitPower",
	Pursuit = "Pursuit",
	PlayRec = "PlayRec",
	BlockShed = "BlockShed",
	FinesseMoves = "FinesseMoves",
	PowerMoves = "PowerMoves",
	ManCover = "ManCover",
	ZoneCover = "ZoneCover",
	Press = "Press",
	KickPower = "KickPower",
	KickAccuracy = "KickAccuracy",
}

export enum AttributeGrade {
	A = "A",
	B = "B",
	C = "C",
	D = "D",
	F = "F",
}

export enum DraftRound {
	Top5 = "Top5",
	Round1to2 = "Round1to2",
	Round2to3 = "Round2to3",
	Round3to4 = "Round3to4",
	Day3 = "Day3",
	Udfa = "Udfa",
}

