import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import globalReducer from "../features/global/store/global-slice";
import leaguesReducer from "../features/league/store/leagues-slice";

export const store = configureStore({
  reducer: {
    global: globalReducer,
    leagues: leaguesReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
