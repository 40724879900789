import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LeagueResponse } from "../../../generated/api_types";
import { RootState } from "../../../redux/store";

export interface LeaguesState {
  leagues: LeagueResponse[];
  loading: boolean;
}

const initialState: LeaguesState = {
  leagues: [],
  loading: true,
};

export const leaguesSlice = createSlice({
  name: "leagues",
  initialState,
  reducers: {
    setLeagues: (
      state,
      action: PayloadAction<{ leagues: LeagueResponse[] }>
    ) => {
      state.leagues = action.payload.leagues;
      state.loading = false;
    },
  },
});

export const { setLeagues } = leaguesSlice.actions;

export const selectLeagues = (state: RootState) => state.leagues.leagues;
export const selectLeaguesLoading = (state: RootState) => state.leagues.loading;

export default leaguesSlice.reducer;
