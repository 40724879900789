import { useAppDispatch } from "../../../redux/hooks";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import LeagueNav from "./nav";
import LoadingPage from "../../global/components/loading-page";
import { useState } from "react";
import { PlayoffsResponse } from "../../../generated/api_types";

function Playoffs() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const leagueName = params["leagueName"];

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null as PlayoffsResponse | null);

  return (
    <Container>
      <LeagueNav />

      {loading ? <LoadingPage /> : <div></div>}
    </Container>
  );
}

export default Playoffs;
