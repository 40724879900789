import "./styles/index.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./app";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import LeagueHome from "./features/league/components/home";
import Home from "./features/home/components/home";
import AuthCallback from "./features/auth/components/callback";
import DraftList from "./features/draft/components/draft-list";
import DraftBoard from "./features/draft/components/draft-board";
import Playoffs from "./features/league/components/playoffs";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "auth/callback",
        element: <AuthCallback />,
      },
      {
        path: "draft",
        element: <DraftList />,
      },
      {
        path: "draft/:draftBoardId",
        element: <DraftBoard />,
      },
      {
        path: "leagues/:leagueName",
        element: <LeagueHome />,
      },
      {
        path: "leagues/:leagueName/playoffs",
        element: <Playoffs />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
