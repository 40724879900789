import { Nav } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

function LeagueNav() {
  const location = useLocation();
  const params = useParams();
  const leagueName = params["leagueName"];

  return (
    <Nav variant="tabs" activeKey={location.pathname} className="my-3">
      <LinkContainer to={`/leagues/${leagueName}`}>
        <Nav.Link>Standings</Nav.Link>
      </LinkContainer>
      <LinkContainer to={`/leagues/${leagueName}/playoffs`}>
        <Nav.Link>Playoffs</Nav.Link>
      </LinkContainer>
    </Nav>
  );
}

export default LeagueNav;
