import React, { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Messages, {
  GENERIC_ERROR_MESSAGE,
} from "./features/global/components/messages";
import {
  addMessage,
  removeMessage,
  selectAuth,
  selectMessages,
  setAuth,
} from "./features/global/store/global-slice";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { AuthResponse, MessageSeverity } from "./generated/api_types";
import AuthService from "./api/auth";
import { beginAuthFlow } from "./features/auth/services/discord-auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons/faDiscord";
import LeagueService from "./api/league";
import {
  selectLeagues,
  setLeagues,
} from "./features/league/store/leagues-slice";

const App = () => {
  const messages = useAppSelector(selectMessages);
  const auth = useAppSelector(selectAuth);
  const leagues = useAppSelector(selectLeagues);
  const dispatch = useAppDispatch();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [authLoaded, setAuthLoaded] = useState(false);
  const [authTimer, setAuthTimer] = useState(null as number | null);

  const refreshAuth = useCallback(
    async (refresh_token: string) => {
      try {
        const resp = await AuthService.putAuth({
          refresh_token,
        });
        if (resp.message) {
          addMessage({ message: { ...resp.message, timestamp: Date.now() } });
        }
        if (resp.data) {
          localStorage.setItem("auth", JSON.stringify(resp.data));
          dispatch(setAuth({ auth: resp.data }));
        }
      } catch (e) {
        dispatch(
          addMessage({
            message: {
              severity: MessageSeverity.Error,
              message: GENERIC_ERROR_MESSAGE,
              timestamp: Date.now(),
            },
          })
        );
      }
    },
    [dispatch]
  );

  const logout = useCallback(() => {
    localStorage.removeItem("auth");
    window.clearTimeout(authTimer!);
    setAuthTimer(null);
    setAuthLoaded(false);
    dispatch(setAuth({ auth: null }));
    navigate("/");
  }, [dispatch, authTimer, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      const storedAuthJson = localStorage.getItem("auth");
      if (!pageLoaded && storedAuthJson) {
        // Initial page load
        const storedAuth: AuthResponse = JSON.parse(storedAuthJson);
        // This will then trigger another state change which will trigger the next if block
        await refreshAuth(storedAuth.oauth.refresh_token);
      } else if (auth && !authLoaded) {
        setAuthTimer(
          window.setTimeout(
            () => refreshAuth(auth.oauth.refresh_token),
            auth.oauth.expires_in * 1000 - 60000
          )
        );
        setAuthLoaded(true);
      }
      if (!pageLoaded) {
        setPageLoaded(true);
      }
    };
    fetchData().catch(console.error);
  }, [pageLoaded, auth, authLoaded, refreshAuth]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await LeagueService.getLeagues();
        dispatch(setLeagues({ leagues: resp.data! }));
        if (resp.message) {
          dispatch(
            addMessage({ message: { ...resp.message, timestamp: Date.now() } })
          );
        }
      } catch (e) {
        dispatch(
          addMessage({
            message: {
              severity: MessageSeverity.Error,
              message: GENERIC_ERROR_MESSAGE,
              timestamp: Date.now(),
            },
          })
        );
      }
    };
    fetchData().catch(console.error);
  }, [dispatch]);

  return (
    <Container fluid>
      <Navbar variant="dark" bg="primary" expand="lg" className="mb-4">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>MADDEN Advanced</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" activeKey={location.pathname}>
              <NavDropdown
                title={params["leagueName"] ? params["leagueName"] : "Leagues"}
                active={!!params["leagueName"]}
              >
                {leagues.map((league) => (
                  <LinkContainer key={league.id} to={`/leagues/${league.name}`}>
                    <NavDropdown.Item
                      active={(params["leagueName"] || "") === league.name}
                    >
                      {league.display_name}
                    </NavDropdown.Item>
                  </LinkContainer>
                ))}
              </NavDropdown>
            </Nav>
            <Nav>
              <LinkContainer to={`/draft`}>
                <Nav.Link>Draft Tool</Nav.Link>
              </LinkContainer>
              {auth ? (
                <Nav.Link onClick={logout}>Logout</Nav.Link>
              ) : (
                <Nav.Link onClick={beginAuthFlow}>
                  <FontAwesomeIcon icon={faDiscord} /> Login
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />

      <Messages
        messages={messages}
        clearMessage={(i) => dispatch(removeMessage({ index: i }))}
      />
    </Container>
  );
};

export default App;
