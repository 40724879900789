import Select from "react-select";
import StateManagedSelect from "react-select/dist/declarations/src/stateManager";

export type ReactSelectOption = { value: string; label: string };

const StyledReactSelect: StateManagedSelect = (props) => {
  return (
    <Select
      {...props}
      classNames={{
        option: (state) =>
          state.isSelected ? "text-white bg-primary" : "text-black",
      }}
    />
  );
};
export default StyledReactSelect;
