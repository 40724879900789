export const oauth_redirect_uri = `${process.env.REACT_APP_PUBLIC_URL}/auth/callback`;

export const beginAuthFlow = () => {
  const client_id = "1048070019923320872";
  const authorizationUrl = new URL("https://discord.com/oauth2/authorize");
  authorizationUrl.searchParams.set("response_type", "code");
  authorizationUrl.searchParams.set("client_id", client_id);
  authorizationUrl.searchParams.set("scope", "identify");
  authorizationUrl.searchParams.set("redirect_uri", oauth_redirect_uri);
  authorizationUrl.searchParams.set("prompt", "none");
  window.location.assign(authorizationUrl);
};
