import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { addMessage, selectAuth } from "../../global/store/global-slice";
import { useParams } from "react-router-dom";
import {
  FormEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Alert,
  Button,
  Container,
  Form,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  flexRender,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import LoadingPage from "../../global/components/loading-page";
import {
  Archetype,
  Attribute,
  AttributeGrade,
  AttributeVerboseInfo,
  DraftBoardArchetypeResponse,
  DraftBoardListingResponse,
  DraftBoardPlayerResponse,
  DraftRound,
  MessageSeverity,
  Position,
} from "../../../generated/api_types";
import DraftBoardService from "../../../api/draft-board";
import { GENERIC_ERROR_MESSAGE } from "../../global/components/messages";
import StyledReactSelect, {
  ReactSelectOption,
} from "../../global/components/react-select";
import clone from "lodash/clone";
import find from "lodash/find";
import mapValues from "lodash/mapValues";
import remove from "lodash/remove";
import startCase from "lodash/startCase";
import unset from "lodash/unset";
import { AppDispatch } from "../../../redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";

const roundOptions = [
  { value: DraftRound.Top5, label: "Top 5" },
  { value: DraftRound.Round1to2, label: "Rd 1-2" },
  { value: DraftRound.Round2to3, label: "Rd 2-3" },
  { value: DraftRound.Round3to4, label: "Rd 3-4" },
  { value: DraftRound.Day3, label: "Day 3" },
  { value: DraftRound.Udfa, label: "UDFA" },
];
const attributeOptions = [
  { value: AttributeGrade.A, label: "A" },
  { value: AttributeGrade.B, label: "B" },
  { value: AttributeGrade.C, label: "C" },
  { value: AttributeGrade.D, label: "D" },
  { value: AttributeGrade.F, label: "F" },
];
const specialAttributeOptions = [
  { value: AttributeGrade.A, label: "Great-Elite" },
  { value: AttributeGrade.B, label: "Good-Great" },
  { value: AttributeGrade.C, label: "Average" },
  { value: AttributeGrade.D, label: "Marginal" },
  { value: AttributeGrade.F, label: "Poor" },
];

export default function DraftBoard() {
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const params = useParams();
  const draftBoardId = params["draftBoardId"]!;
  const [loading, setLoading] = useState(true);
  const [boardInfo, setBoardInfo] = useState(
    null as null | DraftBoardListingResponse
  );
  const [players, setPlayers] = useState([] as DraftBoardPlayerResponse[]);
  const [archetypes, setArchetypes] = useState(
    {} as DraftBoardArchetypeResponse
  );
  const [editRows, setEditRows] = useState([] as string[]);
  const [sorting, setSorting] = useState<SortingState>([]);

  const loadDraftBoardDetails = useCallback(async () => {
    try {
      const resp = await DraftBoardService.getDraftBoardDetails(
        auth!.oauth.access_token,
        draftBoardId!
      );
      if (resp.message) {
        dispatch(
          addMessage({ message: { ...resp.message, timestamp: Date.now() } })
        );
      }
      if (resp.data) {
        setBoardInfo(resp.data);
      }
    } catch (e) {
      dispatch(
        addMessage({
          message: {
            severity: MessageSeverity.Error,
            message: GENERIC_ERROR_MESSAGE,
            timestamp: Date.now(),
          },
        })
      );
    }
  }, [auth, dispatch, draftBoardId]);

  const loadPlayers = useCallback(async () => {
    try {
      const resp = await DraftBoardService.listDraftBoardPlayers(
        auth!.oauth.access_token,
        draftBoardId!
      );
      if (resp.message) {
        dispatch(
          addMessage({ message: { ...resp.message, timestamp: Date.now() } })
        );
      }
      if (resp.data) {
        setPlayers(resp.data);
      }
    } catch (e) {
      dispatch(
        addMessage({
          message: {
            severity: MessageSeverity.Error,
            message: GENERIC_ERROR_MESSAGE,
            timestamp: Date.now(),
          },
        })
      );
    }
  }, [auth, dispatch, draftBoardId]);

  const loadArchetypes = useCallback(async () => {
    try {
      const resp = await DraftBoardService.getDraftBoardArchetypes();
      if (resp.message) {
        dispatch(
          addMessage({ message: { ...resp.message, timestamp: Date.now() } })
        );
      }
      if (resp.data) {
        setArchetypes(resp.data);
      }
    } catch (e) {
      dispatch(
        addMessage({
          message: {
            severity: MessageSeverity.Error,
            message: GENERIC_ERROR_MESSAGE,
            timestamp: Date.now(),
          },
        })
      );
    }
  }, [dispatch]);

  const finishEditing = useCallback(
    async (playerId: string | null) => {
      await loadPlayers();

      if (playerId !== null) {
        const newEditRows = clone(editRows);
        remove(newEditRows, (v) => v === playerId);
        setEditRows(newEditRows);
      }
    },
    [loadPlayers, editRows, setEditRows]
  );

  const toggleDrafted = useCallback(
    async (playerId: string) => {
      try {
        const player = players.find((p) => p.id === playerId)!;
        const resp = await DraftBoardService.setPlayerDrafted(
          auth!.oauth.access_token,
          draftBoardId,
          player.id,
          { drafted: !player.drafted }
        );
        if (resp.message) {
          dispatch(
            addMessage({ message: { ...resp.message, timestamp: Date.now() } })
          );
          if (resp.message.severity === MessageSeverity.Success) {
            await loadPlayers();
          }
        }
      } catch (e) {
        dispatch(
          addMessage({
            message: {
              severity: MessageSeverity.Error,
              message: GENERIC_ERROR_MESSAGE,
              timestamp: Date.now(),
            },
          })
        );
      }
    },
    [auth, draftBoardId, players, dispatch, loadPlayers]
  );

  const addToEditRows = useCallback(
    (playerId: string) => {
      const newEditRows = clone(editRows);
      newEditRows.push(playerId);
      setEditRows(newEditRows);
    },
    [editRows, setEditRows]
  );

  const columnHelper = createColumnHelper<DraftBoardPlayerResponse>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("drafted", {
        header: "Drafted",
        cell: (props) => {
          return (
            <Form.Check
              checked={props.getValue()}
              onChange={async () => {
                await toggleDrafted(props.row.id);
              }}
            />
          );
        },
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "text",
      }),
      columnHelper.accessor("listed_position", {
        header: "Pos",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "text",
      }),
      columnHelper.accessor("archetype", {
        header: "Ideal Type",
        cell: (props) => formatArchetype(props.getValue()),
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: (a, b) => {
          if (a.original.listed_position > b.original.listed_position) {
            return -1;
          }
          if (a.original.listed_position < b.original.listed_position) {
            return 1;
          }
          if (a.original.archetype > b.original.archetype) {
            return -1;
          }
          if (a.original.archetype < b.original.archetype) {
            return 1;
          }
          return 0;
        },
      }),
      columnHelper.accessor("name", {
        header: "Player Name",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "text",
      }),
      columnHelper.accessor(
        (row) =>
          row.projected_round
            ? draftRoundToInt(row.projected_round)
            : undefined,
        {
          header: "Proj. Round",
          cell: (props) =>
            props.row.original.projected_round
              ? formatRound(props.row.original.projected_round)
              : "?",
          enableSorting: true,
          enableMultiSort: true,
          sortingFn: "basic",
          invertSorting: true,
          sortUndefined: 1,
        }
      ),
      columnHelper.accessor(
        (row) =>
          row.actual_round ? draftRoundToInt(row.actual_round) : undefined,
        {
          header: "Actual Talent",
          cell: (props) =>
            props.row.original.actual_round
              ? formatRound(props.row.original.actual_round)
              : "?",
          enableSorting: true,
          enableMultiSort: true,
          sortingFn: "basic",
          invertSorting: true,
          sortUndefined: 1,
        }
      ),
      columnHelper.accessor("age", {
        header: "Age",
        cell: (props) => props.getValue() || "?",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "basic",
        sortUndefined: 1,
      }),
      columnHelper.accessor("height", {
        header: "Height",
        cell: (props) =>
          props.getValue() ? heightToString(props.getValue()!) : "?",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "basic",
        sortUndefined: 1,
      }),
      columnHelper.accessor("weight", {
        header: "Weight",
        cell: (props) => props.getValue() || "?",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "basic",
        sortUndefined: 1,
      }),
      columnHelper.accessor("forty_time", {
        header: "40",
        cell: (props) => props.getValue()?.toFixed(2) || "?",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "basic",
        invertSorting: true,
        sortUndefined: 1,
      }),
      columnHelper.accessor("bench_press_reps", {
        header: "Bench",
        cell: (props) => props.getValue()?.toFixed(0) || "?",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "basic",
        sortUndefined: 1,
      }),
      columnHelper.accessor("vert_jump_height", {
        header: "Vert",
        cell: (props) => props.getValue()?.toFixed(1) || "?",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "basic",
        sortUndefined: 1,
      }),
      columnHelper.accessor("three_cone_time", {
        header: "3 Cone",
        cell: (props) => props.getValue()?.toFixed(2) || "?",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "basic",
        invertSorting: true,
        sortUndefined: 1,
      }),
      columnHelper.accessor("shuttle_time", {
        header: "Shuttle",
        cell: (props) => props.getValue()?.toFixed(2) || "?",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "basic",
        invertSorting: true,
        sortUndefined: 1,
      }),
      columnHelper.group({
        header: "Attr. 1",
        columns: [
          columnHelper.display({
            id: "attr1Name",
            cell: (props) =>
              selectAttribute(
                props.row.original,
                archetypes[props.row.original.listed_position][
                  props.row.original.archetype
                ],
                0
              )[0],
          }),
          columnHelper.accessor(
            (row) =>
              selectAttribute(
                row,
                archetypes[row.listed_position][row.archetype],
                0
              )[1],
            {
              id: "attr1Value",
              cell: (props) => props.getValue() || "?",
            }
          ),
        ],
      }),
      columnHelper.group({
        header: "Attr. 2",
        columns: [
          columnHelper.display({
            id: "attr2Name",
            cell: (props) =>
              selectAttribute(
                props.row.original,
                archetypes[props.row.original.listed_position][
                  props.row.original.archetype
                ],
                1
              )[0],
          }),
          columnHelper.accessor(
            (row) =>
              selectAttribute(
                row,
                archetypes[row.listed_position][row.archetype],
                1
              )[1],
            {
              id: "attr2Value",
              cell: (props) => props.getValue() || "?",
            }
          ),
        ],
      }),
      columnHelper.group({
        header: "Attr. 3",
        columns: [
          columnHelper.display({
            id: "attr3Name",
            cell: (props) =>
              selectAttribute(
                props.row.original,
                archetypes[props.row.original.listed_position][
                  props.row.original.archetype
                ],
                2
              )[0],
          }),
          columnHelper.accessor(
            (row) =>
              selectAttribute(
                row,
                archetypes[row.listed_position][row.archetype],
                2
              )[1],
            {
              id: "attr3Value",
              cell: (props) => props.getValue() || "?",
            }
          ),
        ],
      }),
      columnHelper.group({
        header: "Attr. 4",
        columns: [
          columnHelper.display({
            id: "attr4Name",
            cell: (props) =>
              selectAttribute(
                props.row.original,
                archetypes[props.row.original.listed_position][
                  props.row.original.archetype
                ],
                3
              )[0],
          }),
          columnHelper.accessor(
            (row) =>
              selectAttribute(
                row,
                archetypes[row.listed_position][row.archetype],
                3
              )[1],
            {
              id: "attr4Value",
              cell: (props) => props.getValue() || "?",
            }
          ),
        ],
      }),
      columnHelper.group({
        header: "Attr. 5",
        columns: [
          columnHelper.display({
            id: "attr5Name",
            cell: (props) =>
              selectAttribute(
                props.row.original,
                archetypes[props.row.original.listed_position][
                  props.row.original.archetype
                ],
                4
              )[0],
          }),
          columnHelper.accessor(
            (row) =>
              selectAttribute(
                row,
                archetypes[row.listed_position][row.archetype],
                4
              )[1],
            {
              id: "attr5Value",
              cell: (props) => props.getValue() || "?",
            }
          ),
        ],
      }),
      columnHelper.accessor("estimate_spd", {
        header: "SPD",
        cell: (props) => props.getValue()?.toFixed(0) || "?",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "basic",
        sortUndefined: 1,
      }),
      columnHelper.accessor("estimate_acc", {
        header: "ACC",
        cell: (props) => props.getValue()?.toFixed(0) || "?",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "basic",
        sortUndefined: 1,
      }),
      columnHelper.accessor("estimate_agi", {
        header: "AGI",
        cell: (props) => props.getValue()?.toFixed(0) || "?",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "basic",
        sortUndefined: 1,
      }),
      columnHelper.accessor("estimate_cod", {
        header: "COD",
        cell: (props) => props.getValue()?.toFixed(0) || "?",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "basic",
        sortUndefined: 1,
      }),
      columnHelper.accessor("estimate_str", {
        header: "STR",
        cell: (props) => props.getValue()?.toFixed(0) || "?",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "basic",
        sortUndefined: 1,
      }),
      columnHelper.accessor("estimate_jmp", {
        header: "JMP",
        cell: (props) => props.getValue()?.toFixed(0) || "?",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "basic",
        sortUndefined: 1,
      }),
      columnHelper.accessor("physical_score", {
        header: "Phys. Score",
        cell: (props) => props.getValue()?.toFixed(1) || "?",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "basic",
        sortUndefined: 1,
      }),
      columnHelper.accessor("overall_score", {
        header: "Ovr. Grade",
        cell: (props) => props.getValue()?.toFixed(1) || "?",
        enableSorting: true,
        enableMultiSort: true,
        sortingFn: "basic",
        sortUndefined: 1,
      }),
      columnHelper.accessor("notes", {
        header: "Notes",
        enableSorting: false,
      }),
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: (props) => {
          return (
            <Button
              variant="secondary"
              onClick={() => addToEditRows(props.row.id)}
            >
              Edit
            </Button>
          );
        },
      }),
    ],
    [addToEditRows, archetypes, toggleDrafted, columnHelper]
  );
  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columns,
    data: players,
    getRowId: (row) => row.id,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  useEffect(() => {
    if (auth) {
      setLoading(true);
      Promise.all([loadDraftBoardDetails(), loadPlayers(), loadArchetypes()])
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, [auth, draftBoardId, loadDraftBoardDetails, loadPlayers, loadArchetypes]);

  if (loading) {
    return <LoadingPage />;
  }

  if (!auth) {
    return (
      <Container>
        <Alert variant="info">
          You must be logged in to view or create a draft board
        </Alert>
      </Container>
    );
  }

  if (!boardInfo) {
    return (
      <Container>
        <Alert variant="danger">Failed to load draft board</Alert>
      </Container>
    );
  }

  return (
    <Container fluid>
      <h2>{boardInfo.title} ({players.length} players)</h2>
      <hr />
      <div className="draft-board-table">
        <Table>
          <thead>
            <tr className="bg-dark">
              {table
                .getFlatHeaders()
                .slice(0, columns.length)
                .map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    <div
                      style={
                        header.column.getCanSort() ? { cursor: "pointer" } : {}
                      }
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}{" "}
                      {{
                        asc: <FontAwesomeIcon icon={faArrowUp} size="sm" />,
                        desc: <FontAwesomeIcon icon={faArrowDown} size="sm" />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            <DraftRowEdit
              archetypes={archetypes}
              accessToken={auth.oauth.access_token}
              draftBoardId={draftBoardId}
              dispatch={dispatch}
              finishEditing={async () => {
                await finishEditing(null);
              }}
            />
            {table.getRowModel().rows.map((row) =>
              editRows.includes(row.id) ? (
                <DraftRowEdit
                  key={row.id}
                  existingPlayer={row.original}
                  archetypes={archetypes}
                  accessToken={auth.oauth.access_token}
                  draftBoardId={draftBoardId}
                  dispatch={dispatch}
                  finishEditing={async () => {
                    await finishEditing(row.id);
                  }}
                />
              ) : (
                <tr
                  key={row.id}
                  className={
                    row.original.drafted
                      ? "text-muted bg-body"
                      : (row.original.overall_score || 0) > 90
                      ? "text-black bg-success"
                      : (row.original.overall_score || 0) > 86
                      ? "text-black bg-info"
                      : (row.original.overall_score || 0) > 82
                      ? "bg-primary"
                      : "bg-dark"
                  }
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              )
            )}
          </tbody>
        </Table>
      </div>
    </Container>
  );
}

function formatArchetype(val: string): string {
  return startCase(val);
}

function heightToString(val: number): string {
  const feet = Math.floor(val / 12);
  const inches = val % 12;
  return `${feet.toFixed(0)}'${inches.toFixed(0)}`;
}

function formatRound(round: DraftRound): string {
  return find(roundOptions, (opt) => opt.value === round)!.label;
}

function draftRoundToInt(round: DraftRound): number {
  switch (round) {
    case DraftRound.Top5:
      return 0;
    case DraftRound.Round1to2:
      return 1;
    case DraftRound.Round2to3:
      return 2;
    case DraftRound.Round3to4:
      return 3;
    case DraftRound.Day3:
      return 4;
    case DraftRound.Udfa:
      return 5;
  }
}

function DraftRowEdit(props: {
  existingPlayer?: DraftBoardPlayerResponse;
  archetypes: DraftBoardArchetypeResponse;
  accessToken: string;
  draftBoardId: string;
  dispatch: AppDispatch;
  finishEditing: () => Promise<void>;
}) {
  const {
    existingPlayer,
    archetypes,
    accessToken,
    draftBoardId,
    dispatch,
    finishEditing,
  } = props;

  const heightRegex = /^(\d)'([0-9]|10|11)$/;

  const [loading, setLoading] = useState(false);
  const existingPosition = existingPlayer?.listed_position;
  const [position, setPosition] = useState(
    existingPosition
      ? { value: existingPosition as string, label: existingPosition as string }
      : null
  );
  const existingArchetype = existingPlayer?.archetype;
  const [archetype, setArchetype] = useState(
    existingArchetype
      ? {
          value: existingArchetype as string,
          label: formatArchetype(existingArchetype as string),
        }
      : null
  );
  const [name, setName] = useState(existingPlayer?.name || "");
  const [age, setAge] = useState(existingPlayer?.age?.toString() || "");
  const [height, setHeight] = useState(
    existingPlayer?.height ? heightToString(existingPlayer?.height) : ""
  );
  const [weight, setWeight] = useState(
    existingPlayer?.weight?.toString() || ""
  );
  const existingProjRound = existingPlayer?.projected_round;
  const [projectedRound, setProjectedRound] = useState(
    existingProjRound
      ? {
          value: existingProjRound as string,
          label: formatRound(existingProjRound),
        }
      : null
  );
  const existingActualRound = existingPlayer?.actual_round;
  const [actualRound, setActualRound] = useState(
    existingActualRound
      ? {
          value: existingActualRound as string,
          label: formatRound(existingActualRound),
        }
      : null
  );
  const [notes, setNotes] = useState(existingPlayer?.notes || "");
  const [fortyTime, setFortyTime] = useState(
    existingPlayer?.forty_time?.toFixed(2) || ""
  );
  const [threeConeTime, setThreeConeTime] = useState(
    existingPlayer?.three_cone_time?.toFixed(2) || ""
  );
  const [shuttleTime, setShuttleTime] = useState(
    existingPlayer?.shuttle_time?.toFixed(2) || ""
  );
  const [benchPressReps, setBenchPressReps] = useState(
    existingPlayer?.bench_press_reps?.toFixed(0) || ""
  );
  const [vertJump, setVertJump] = useState(
    existingPlayer?.vert_jump_height?.toFixed(1) || ""
  );
  const [attributes, setAttributes] = useState(
    existingPlayer
      ? existingPlayer.attributes.reduce((attrs, thisAttr) => {
          attrs[thisAttr.attribute] = {
            value: thisAttr.grade as string,
            label: thisAttr.grade as string,
          };
          return attrs;
        }, {} as Record<Attribute, ReactSelectOption>)
      : ({} as Record<Attribute, ReactSelectOption>)
  );
  const [invalidReason, setInvalidReason] = useState(null as null | string);

  const archetypeAttributes = archetype
    ? archetypes[position!.value as Position][archetype.value as Archetype]
    : [];

  // Validation
  let newInvalidReason = null;
  if (position === null) {
    newInvalidReason = "Position must be set";
  } else if (archetype === null) {
    newInvalidReason = "Archetype must be set";
  } else if (name === "") {
    newInvalidReason = "Player name must be set";
  } else if (age !== "") {
    const ageInt = Number.parseInt(age, 10);
    const isValidAge = Number.isSafeInteger(ageInt) && ageInt > 0;
    if (!isValidAge) {
      newInvalidReason = 'Age must be a positive number, e.g. "21"';
    }
  } else if (height !== "" && !heightRegex.test(height)) {
    newInvalidReason = 'Height must be in feet and inches, e.g. "5\'11"';
  } else if (weight !== "") {
    const weightInt = Number.parseInt(weight, 10);
    const isValidWeight = Number.isSafeInteger(weightInt) && weightInt > 0;
    if (!isValidWeight) {
      newInvalidReason =
        'Weight must be a positive number of pounds, e.g. "240"';
    }
  } else if (fortyTime !== "") {
    const valFloat = Number.parseFloat(fortyTime);
    const isValid = Number.isFinite(valFloat) && valFloat > 0;
    if (!isValid) {
      newInvalidReason = 'Forty time must be a positive number, e.g. "4.20"';
    }
  } else if (threeConeTime !== "") {
    const valFloat = Number.parseFloat(threeConeTime);
    const isValid = Number.isFinite(valFloat) && valFloat > 0;
    if (!isValid) {
      newInvalidReason =
        'Three-cone time must be a positive number, e.g. "4.20"';
    }
  } else if (shuttleTime !== "") {
    const valFloat = Number.parseFloat(shuttleTime);
    const isValid = Number.isFinite(valFloat) && valFloat > 0;
    if (!isValid) {
      newInvalidReason = 'Shuttle time must be a positive number, e.g. "4.20"';
    }
  } else if (benchPressReps !== "") {
    const valInt = Number.parseInt(benchPressReps);
    const isValid = Number.isSafeInteger(valInt) && valInt > 0;
    if (!isValid) {
      newInvalidReason =
        'Bench press reps must be a positive number, e.g. "42"';
    }
  } else if (vertJump !== "") {
    const valFloat = Number.parseFloat(vertJump);
    const isValid = Number.isFinite(valFloat) && valFloat > 0;
    if (!isValid) {
      newInvalidReason = 'Vertical jump must be a positive number, e.g. "42.0"';
    }
  }
  if (newInvalidReason !== invalidReason) {
    setInvalidReason(newInvalidReason);
  }

  const clearData = () => {
    setPosition(null);
    setArchetype(null);
    setName("");
    setAge("");
    setHeight("");
    setWeight("");
    setProjectedRound(null);
    setActualRound(null);
    setNotes("");
    setFortyTime("");
    setThreeConeTime("");
    setShuttleTime("");
    setBenchPressReps("");
    setVertJump("");
    setAttributes({} as Record<Attribute, ReactSelectOption>);
  };

  const savePlayer = async (e: FormEvent) => {
    e.preventDefault();

    if (invalidReason) {
      return;
    }

    setLoading(true);
    const heightMatches = height ? height.match(heightRegex) : null;
    console.log(heightMatches);
    const data = {
      listed_position: position!.value as Position,
      archetype: archetype!.value as Archetype,
      name,
      age: age ? Number.parseInt(age) : undefined,
      height: heightMatches
        ? Number.parseInt(heightMatches[1], 10) * 12 +
          Number.parseInt(heightMatches[2], 10)
        : undefined,
      weight: weight ? Number.parseInt(weight) : undefined,
      projected_round: projectedRound?.value as DraftRound | undefined,
      actual_round: actualRound?.value as DraftRound | undefined,
      notes,
      forty_time: fortyTime ? Number.parseFloat(fortyTime) : undefined,
      three_cone_time: threeConeTime
        ? Number.parseFloat(threeConeTime)
        : undefined,
      shuttle_time: shuttleTime ? Number.parseFloat(shuttleTime) : undefined,
      bench_press_reps: benchPressReps
        ? Number.parseInt(benchPressReps, 10)
        : undefined,
      vert_jump_height: vertJump ? Number.parseFloat(vertJump) : undefined,
      attributes: mapValues(attributes, (val) => val.value as AttributeGrade),
      drafted: existingPlayer?.drafted || false,
    };
    try {
      const resp = existingPlayer
        ? await DraftBoardService.updateDraftBoardPlayer(
            accessToken,
            draftBoardId,
            existingPlayer.id,
            data
          )
        : await DraftBoardService.addDraftBoardPlayer(
            accessToken,
            draftBoardId,
            data
          );
      if (resp.message) {
        dispatch(
          addMessage({ message: { ...resp.message, timestamp: Date.now() } })
        );
        if (resp.message.severity === MessageSeverity.Success) {
          await finishEditing();
          if (!existingPlayer) {
            clearData();
          }
        }
      }
    } catch (e) {
      dispatch(
        addMessage({
          message: {
            severity: MessageSeverity.Error,
            message: GENERIC_ERROR_MESSAGE,
            timestamp: Date.now(),
          },
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <tr className="bg-dark">
      <td />
      <td>
        <StyledReactSelect
          menuPortalTarget={document.body}
          styles={{
            container: (base) => ({ ...base, width: 100 }),
            menuPortal: (base) => ({ ...base, zIndex: 4 }),
          }}
          onChange={(val) => {
            if (val !== position) {
              setArchetype(null);
            }
            setPosition(val);
          }}
          options={[
            ...Object.keys(archetypes)
              .sort()
              .map((val) => ({ value: val, label: val })),
          ]}
          value={position}
          isDisabled={loading}
        />
      </td>
      <td>
        <StyledReactSelect
          menuPortalTarget={document.body}
          styles={{
            container: (base) => ({ ...base, width: 180 }),
            menuPortal: (base) => ({ ...base, zIndex: 4 }),
          }}
          isDisabled={position === null || loading}
          onChange={setArchetype}
          options={
            position === null
              ? []
              : [
                  ...Object.keys(archetypes[position.value as Position])
                    .sort()
                    .map((val) => ({
                      value: val,
                      label: formatArchetype(val),
                    })),
                ]
          }
          value={archetype}
        />
      </td>
      <td>
        <Form.Control
          type="text"
          style={{ width: 200 }}
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={loading}
          placeholder="Player Name"
        />
      </td>
      <td>
        <StyledReactSelect
          menuPortalTarget={document.body}
          styles={{
            container: (base) => ({ ...base, width: 110 }),
            menuPortal: (base) => ({ ...base, zIndex: 4 }),
          }}
          onChange={setProjectedRound}
          options={roundOptions}
          value={projectedRound}
          isDisabled={loading}
        />
      </td>
      <td>
        <StyledReactSelect
          menuPortalTarget={document.body}
          styles={{
            container: (base) => ({ ...base, width: 110 }),
            menuPortal: (base) => ({ ...base, zIndex: 4 }),
          }}
          onChange={setActualRound}
          options={roundOptions}
          value={actualRound}
          isDisabled={loading}
        />
      </td>
      <td>
        <Form.Control
          type="text"
          style={{ width: 60 }}
          maxLength={3}
          value={age}
          onChange={(e) => setAge(e.target.value)}
          disabled={loading}
          placeholder="16"
        />
      </td>
      <td>
        <Form.Control
          type="text"
          style={{ width: 60 }}
          maxLength={4}
          value={height}
          onChange={(e) => setHeight(e.target.value)}
          disabled={loading}
          placeholder="1'0"
        />
      </td>
      <td>
        <Form.Control
          type="text"
          style={{ width: 60 }}
          maxLength={3}
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
          disabled={loading}
          placeholder="499"
        />
      </td>
      <td>
        <Form.Control
          type="text"
          style={{ width: 60 }}
          maxLength={4}
          value={fortyTime}
          onChange={(e) => setFortyTime(e.target.value)}
          disabled={loading}
          placeholder="4.20"
        />
      </td>
      <td>
        <Form.Control
          type="text"
          style={{ width: 60 }}
          maxLength={2}
          value={benchPressReps}
          onChange={(e) => setBenchPressReps(e.target.value)}
          disabled={loading}
          placeholder="42"
        />
      </td>
      <td>
        <Form.Control
          type="text"
          style={{ width: 60 }}
          maxLength={4}
          value={vertJump}
          onChange={(e) => setVertJump(e.target.value)}
          disabled={loading}
          placeholder="42.0"
        />
      </td>
      <td>
        <Form.Control
          type="text"
          style={{ width: 60 }}
          maxLength={4}
          value={threeConeTime}
          onChange={(e) => setThreeConeTime(e.target.value)}
          disabled={loading}
          placeholder="4.20"
        />
      </td>
      <td>
        <Form.Control
          type="text"
          style={{ width: 60 }}
          maxLength={4}
          value={shuttleTime}
          onChange={(e) => setShuttleTime(e.target.value)}
          disabled={loading}
          placeholder="4.20"
        />
      </td>
      <AttributeEdit
        attributes={attributes}
        setAttributes={setAttributes}
        archetypeAttributes={archetypeAttributes}
        index={0}
        disabled={loading}
      />
      <AttributeEdit
        attributes={attributes}
        setAttributes={setAttributes}
        archetypeAttributes={archetypeAttributes}
        index={1}
        disabled={loading}
      />
      <AttributeEdit
        attributes={attributes}
        setAttributes={setAttributes}
        archetypeAttributes={archetypeAttributes}
        index={2}
        disabled={loading}
      />
      <AttributeEdit
        attributes={attributes}
        setAttributes={setAttributes}
        archetypeAttributes={archetypeAttributes}
        index={3}
        disabled={loading}
      />
      <AttributeEdit
        attributes={attributes}
        setAttributes={setAttributes}
        archetypeAttributes={archetypeAttributes}
        index={4}
        disabled={loading}
      />
      <td colSpan={6} />
      <td colSpan={2} />
      <td>
        <Form.Control
          type="text"
          style={{ width: 400 }}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Notes"
        />
      </td>
      <td>
        {" "}
        <OverlayTrigger
          overlay={
            invalidReason ? (
              <Tooltip className="bg-danger">{invalidReason}</Tooltip>
            ) : (
              <></>
            )
          }
        >
          <div className="d-inline-block">
            <Button
              variant="primary"
              disabled={!!invalidReason || loading}
              onClick={savePlayer}
            >
              Save
            </Button>
          </div>
        </OverlayTrigger>
      </td>
    </tr>
  );
}

function selectAttribute(
  player: DraftBoardPlayerResponse,
  archetypeAttributes: AttributeVerboseInfo[],
  index: number
) {
  const attribute = archetypeAttributes[index];
  const attributeScore = attribute.uses_combine_score
    ? (
        (player as Record<string, any>)[
          `estimate_${attribute.abbr.toLowerCase()}`
        ] as number | null
      )?.toString()
    : player.attributes.find(
        (attr) => attr.attribute === archetypeAttributes[index].code
      )?.grade;
  return [attribute.abbr, attributeScore];
}

function AttributeEdit(props: {
  attributes: Record<Attribute, ReactSelectOption>;
  setAttributes: (
    val: SetStateAction<Record<Attribute, ReactSelectOption>>
  ) => void;
  archetypeAttributes: AttributeVerboseInfo[];
  index: number;
  disabled: boolean;
}) {
  const { attributes, setAttributes, archetypeAttributes, index, disabled } =
    props;
  return (
    <>
      <td>
        {archetypeAttributes.length > index
          ? archetypeAttributes[index].abbr
          : ""}
      </td>
      <td>
        {archetypeAttributes.length > index &&
        !archetypeAttributes[index].uses_combine_score ? (
          <StyledReactSelect
            menuPortalTarget={document.body}
            styles={{
              container: (base) => ({ ...base, width: 120 }),
              menuPortal: (base) => ({ ...base, zIndex: 4 }),
            }}
            onChange={(val) => {
              const newAttributes = clone(attributes);
              if (val) {
                newAttributes[archetypeAttributes[index].code] = val;
              } else {
                unset(newAttributes, archetypeAttributes[index].code);
              }
              setAttributes(newAttributes);
            }}
            options={
              archetypeAttributes[index].uses_letter_grades
                ? attributeOptions
                : specialAttributeOptions
            }
            value={attributes[archetypeAttributes[index].code] || null}
            isDisabled={disabled}
          />
        ) : null}
      </td>
    </>
  );
}
