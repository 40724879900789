import { LeagueResponse, Response } from "../generated/api_types";

export default class LeagueService {
  static async getLeagues(): Promise<Response<LeagueResponse[]>> {
    const response = await fetch(`/api/leagues`, {
      method: "GET",
    });
    return response.json();
  }
}
