import { ToastContainer } from "react-bootstrap";
import Message, { MessageData } from "./message";

interface Props {
  messages: MessageData[];
  clearMessage: (index: number) => void;
}

function Messages({ messages, clearMessage }: Props) {
  return (
    <ToastContainer position="top-end" className="p-2">
      {messages.map((message, i) => (
        <Message
          key={message.timestamp}
          message={message}
          clearMessage={() => clearMessage(i)}
        />
      ))}
    </ToastContainer>
  );
}

export default Messages;

export const GENERIC_ERROR_MESSAGE =
  "An unexpected error has occurred; try again later";
