import LoadingPage from "../../global/components/loading-page";
import { useEffect } from "react";
import AuthService from "../../../api/auth";
import { MessageSeverity } from "../../../generated/api_types";
import { GENERIC_ERROR_MESSAGE } from "../../global/components/messages";
import { useAppDispatch } from "../../../redux/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addMessage, setAuth } from "../../global/store/global-slice";
import { oauth_redirect_uri } from "../services/discord-auth";

function AuthCallback() {
  const dispatch = useAppDispatch();
  const query = useSearchParams()[0];
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await AuthService.postAuth({
          code: query.get("code") || "",
          redirect_uri: oauth_redirect_uri,
        });
        if (resp.message) {
          dispatch(
            addMessage({ message: { ...resp.message, timestamp: Date.now() } })
          );
        }
        if (resp.data) {
          localStorage.setItem("auth", JSON.stringify(resp.data));
          dispatch(setAuth({ auth: resp.data }));
        }
      } catch (e) {
        dispatch(
          addMessage({
            message: {
              severity: MessageSeverity.Error,
              message: GENERIC_ERROR_MESSAGE,
              timestamp: Date.now(),
            },
          })
        );
      } finally {
        navigate("/");
      }
    };
    fetchData().catch(console.error);
  }, [dispatch, navigate, query]);

  return <LoadingPage />;
}

export default AuthCallback;
